<template>
  <div class="destruction-block" id="destruction-block">
    <div class="text-block-wrapp" :class="{'active': isDestruction}"> 
      <div class="text-block container">
        <div class="title">
          {{$t('destruction_title')}}
        </div>
        <div class="switcher-block" >
          <div class="swipe" :class="{'hidden': isDestruction}">
            <img src="@/assets/img/swipe.png" alt="swipe">
          </div>
          <div class="switcher-wrapp">
            <div class="switcher">
              <label class="switch">
                <input type="checkbox" @click="changeView">
                <span class="slider round"></span>
              </label>
            </div>
            <span class="switcher_text" :class="{'active': isDestruction}">
              {{$t('destruction_subtitle')}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="destruction-block-wrapp">
      <div class="destruction-block__images">
        <div v-for="index in 6" class="destruction-item">
          <img :src="'/img/destruction/'+index+'.jpg'" alt="img">
        </div>
      </div>
      <div class="destruction-block__images absolute" :class="{'active': isDestruction}">
        <div v-for="index in 6" class="destruction-item">
          <img :src="'/img/destruction/'+index+'-1.jpg'" alt="img">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'DestructionBlock',

  data() {
    return{
      isDestruction: false
    }
  },
  computed: {
  },
  methods: {
    changeView(){
      this.isDestruction =!this.isDestruction;
    }
  }
}

</script>

<style lang="scss">
  .destruction-block{
    .text-block{
      max-width: 990px !important;
      .title {
        font-weight: 700;
        font-size: 85px;
        line-height: 109px;
        letter-spacing: -0.05em;
        color: #10142D;
        transition: color .4s ease-in-out;
        @media (max-width: 767px){
          font-size: 42px;
          line-height: 57px;
        }
      }
      &-wrapp{
        padding-top: 110px;
        padding-bottom: 70px;
        transition: background-color .35s ease-in-out;
        @media (max-width: 767px){
          padding-top: 57px;
          padding-bottom: 40px;
        }
        &.active{
          background: #10142D;
          .title {
            color: #fff;
          }
        }
      }
      .switcher-block{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        padding-right: 200px;
        @media (max-width: 767px){
          align-items: flex-start;
          margin-top: 40px;
          padding-right: 94px;
        }
        .swipe {
          width: 128px;
          height: 128px;
          opacity: 1;
          transition: opacity .3s ease-in-out;
          @media (max-width: 767px){
            width: 82px;
            height: 82px;
            min-width: 82px;
          }
          &.hidden{
            opacity: 0;
          }
        }
        .switcher-wrapp{
          display: flex;
          flex-direction: column;
          margin-left: 26px;
          @media (max-width: 767px){
            margin-left: 12px;
          }
          .switcher_text{
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #6D6D6D;
            margin-top: 20px;
            transition: color .35s ease-in-out;
            &.active{
              color: #fff;
            }
          }
        }
      }
    }
    &__images{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-gap: 0;
      margin-bottom: 40px;
      @media (max-width: 767px){
        grid-template-columns: 1fr 1fr;
        margin-bottom: 0;
      }
      .destruction-item{
        height: 33.33vw;
        width: 100%;
        @media (max-width: 767px){
          height: 50vw;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &.absolute{
        position: absolute; 
        top: 0;
        bottom: 0;
        width: 100%;
        opacity: 0;
        transition: opacity .45s ease-in-out;
        &.active{
          opacity: 1;
        }
      }
    }
    &-wrapp{
      position: relative;
    }
}


.switch {
  position: relative;
  display: inline-block;
  width: 143px;
  height: 72px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 60px;
  width: 60px;
  left: 6px;
  bottom: 6px;
  background-color: #9F9F9F;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #fff;
  &:before{
    background-color: #47D85E;
  }
}

input:focus + .slider {
  box-shadow: 0 0 1px #fff;
  &:before{
    background-color: #47D85E;
  }
}

input:checked + .slider:before {
  -webkit-transform: translateX(72px);
  -ms-transform: translateX(72px);
  transform: translateX(72px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 50px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>