<template>
		<div class="video__block">
      <div class="video-overlay" :class="{'video-overlay--close': isPlay}" @click="playVideo">
        <img src="@/assets/img/play-img.png" alt="play">
      </div>
      <video ref="video" :poster="poster" :src="video" type="video/mp4" preload="none"></video>
    </div> 
</template>

<script>
export default {

  name: 'VideoBlock',

  data () {
    return {
      video: '/video/main.mp4',
      poster: '/video/poster.png',
      isPlay: false
    }
  },
  methods: {
  	openFullScreen(){
  		let elem = this.$refs.video;
  		if (elem.requestFullscreen) {
		    elem.requestFullscreen();
		  } else if (elem.webkitRequestFullscreen) { /* Safari */
		    elem.webkitRequestFullscreen();
		  } else if (elem.msRequestFullscreen) { /* IE11 */
		    elem.msRequestFullscreen();
		  }
  	},
    playVideo(){
      this.isPlay = true;
      this.$refs.video.controls = true;
      this.$refs.video.play();
      console.log(this.$refs.video);
     this.openFullScreen();
    }
  }
}

</script>

<style lang="scss">

.video__block{
  width: 100%;
  text-align: center;
  margin: auto;
  position: relative;
  video{
  	width: 100%;
	}
	.video-overlay{
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		cursor: pointer;
		z-index: 200;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: 103px;
			height: 103px;
			@media (max-width: 1023px) {
				width: 32px;
				height: 32px;
			}
			@media (max-width: 480px) {
				width: 26px;
				height: 26px;
			}
		}
		&--close{
			display: none;
		}
		
	}
}


</style>