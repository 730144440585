<template>
	<div class="nav-wrapp" :class="{ 'nav-wrapp--fixed': isFixed, 'nav-wrapp--hideMenu': hideMenu}">
			Nav
	</div>
</template>

<script>
export default {

  name: 'Nav',

  data () {
    return {
      langs: ['ru','ua'],
      scrollBuy: "form-scroll",
      isToogle: false,
      isTablet: false,
      isTabletPC: false,
    	isMobile: false,
      isSmallPhone: false,
      isToSmallPhone: false,
    	isNavsButon: false,
    	isFixed: false,
    	hideMenu: false,
    	menuHidden: false,
      lastScrollTop: 0,
      itemsStartValue: 3,
      visible: false,
      isHome: true,
      headerScroll: 'header-scroll'

    }
  },
  watch: {
    $route(to, from) {
      
    }
  },
  computed: {
    // lang(){
    //   return this.$i18n.locale;
    // }
  },
  methods: {
    toogleClick: function(event) {
      this.isToogle = !this.isToogle;
      this.visible = !this.visible;
    },
  	showHiddenMenu: function(event){
      this.menuHidden = !this.menuHidden;
    },
  	mobileVers: function() {
      this.isMobile = window.innerWidth < 767;
      this.isSmallPhone = window.innerWidth < 400;
      this.isToSmallPhone = window.innerWidth < 375;
      this.isTablet = window.innerWidth < 1023;
      this.isTabletPC = window.innerWidth < 1439;
    },
    menuFixed: function() {
      let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

      if (40 > scrollTop) {
        this.isFixed = false;
      } else {
        this.isFixed = true;
      }

      if (scrollTop > 300 && !this.isToogle) {
        if (scrollTop > this.lastScrollTop) {
          this.hideMenu = true;
          this.menuHidden = false;
        } else {
          this.hideMenu = false;
        }
        this.lastScrollTop = scrollTop;
      }
    }
    
  },
  mounted: function(){
    this.mobileVers();
  },
  created: function() {
      window.addEventListener('scroll', this.menuFixed);
    	window.addEventListener('resize', this.mobileVers);
  },
  beforeDestroy: function() {
      window.removeEventListener('scroll', this.menuFixed);
    	window.removeEventListener('resize', this.mobileVers);
  }
}
</script>

<style>

</style>