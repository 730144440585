<template>
	<div class="line-block-wrapp">
    <div class="line-block">
      <span>Our goal — #HelpToUkraine</span> <img src="@/assets/img/uk_flag.png" alt="flag"> 
      <span>Our goal — #HelpToUkraine</span> <img src="@/assets/img/uk_flag.png" alt="flag">
      <span>Our goal — #HelpToUkraine</span> <img src="@/assets/img/uk_flag.png" alt="flag"> 
    </div>
  </div>
</template>

<script>
export default {

  name: 'LineHelpBlock',

  data() {
    return{
    }
  },
  computed: {
  },
  methods: {
  }
}

</script>

<style lang="scss">
	.line-block{
    height: 221px;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 85px;
    line-height: 109px;
    letter-spacing: -0.05em;
    color: #10142D;
    white-space: nowrap;
    transform: translateX(-60px);
    @media (max-width: 767px){
      font-size: 42px;
      line-height: 54px;
      height: 130px;
      transform: translateX(-90%);
    }
    &-wrapp{
      width: 100%;
      overflow: hidden;
      background: #F3F3F3;
    }
    img {
      width: 121px;
      height: 121px;
      margin: 0 21px;
      @media (max-width: 767px){
        width: 73px;
        height: 73px;
        margin: 0 13px;
      }
    }
    &:hover{
      &.line-block{
        animation: ticker 10s linear infinite;
      }
    }
  }

 @keyframes ticker {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>