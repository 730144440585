<template>
	<div v-if="!isMobile">
		<div class="help-text-blocks container">
			<div class="item">
				{{$t('help_text-left')}}
			</div>
			<div class="item">		
				{{$t('help_text-right')}} 
			</div>
		</div>
		<div class="help-img-blocks">
			<div>
				<img src="@/assets/img/help/1.jpg" alt="1">
			</div>
			<div>
				<img src="@/assets/img/help/4.jpg" alt="4">
			</div>
			<div>
				<img src="@/assets/img/help/3.jpg" alt="3">
			</div>
			<div>
				<img src="@/assets/img/help/2.jpg" alt="2">
			</div>
		</div>
	</div>
	<div v-else>
		<div class="help-text-blocks">
			<div class="item">
				{{$t('help_text-left')}}
			</div>
		</div>
		<div class="help-img-blocks">
			<div>
				<img src="@/assets/img/help/1.jpg" alt="1">
			</div>
			<div>
				<img src="@/assets/img/help/4.jpg" alt="4">
			</div>
		</div>
		<div class="help-text-blocks">
			<div class="item">
				{{$t('help_text-right')}}
			</div>
		</div>
		<div class="help-img-blocks">
			<div>
				<img src="@/assets/img/help/3.jpg" alt="3">
			</div>
			<div>
				<img src="@/assets/img/help/2.jpg" alt="2">
			</div>
		</div>
	</div>
</template>

<script>
export default {

  name: 'HelpTextImgBlock',

  data() {
    return{
    	isMobile: false
    }
  },
  methods: {
  	mobileVers: function() {
      this.isMobile = window.innerWidth < 767;
    }
  },
  mounted: function(){
    this.mobileVers();
  },
  created: function() {
    window.addEventListener('resize', this.mobileVers);
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.mobileVers);
  }
}

</script>

<style lang="scss">
	.help-text-blocks{
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 132px;
		padding-top: 150px !important;
		padding-bottom: 130px !important;
		@media (max-width: 1023px) {
			padding-top: 120px !important;
			padding-bottom: 90px !important;
		}
		@media (max-width: 767px){
			padding: 40px 24px !important;
			grid-template-columns: 1fr;
		}
		.item {
			font-weight: 400;
			font-size: 18px;
			line-height: 27px;
			color: #10142D;
			max-width: 512px;
			@media (max-width: 767px){
				font-size: 16px;
				line-height: 24px;
				max-width: none;
				color: #6D6D6D;
			}
		}
	}
	.help-img-blocks{
		display: flex;
    align-items: center;
    width: 100%;
    background: #F3F3F3;
    div{
    	height: auto;
    	width: 25%;
    	@media (max-width: 767px){
    		width: 50%;
    	}
    	img {
    		width: 100%;
    	}
    }
	}
</style>