<template>
	<div id="text-hover-block" class="text-hover-block">
		<div v-if="lang == 'en'" class="container">
			<span :class="{'active': scrollPosition < 7}"> Hello! </span> <br class="br"/>
			<span :class="{'active': scrollPosition > 7 && scrollPosition < 28}">We are an all - Ukrainian charitable organization,</span> <br class="mobile-br"/> <br class="br"/>
			<span :class="{'active': scrollPosition > 28 && scrollPosition < 42}">which unites Ukrainian and international volunteers.</span><br/> 
			<span :class="{'active': scrollPosition > 42 && scrollPosition < 100}">Our goal is to support Ukraine and prevent a humanitarian crisis.</span> <br class="br"/> 
		</div>
		<div v-else class="container">
			<span :class="{'active': scrollPosition < 7}"> Вітаємо! </span> <br class="br"/>
			<span :class="{'active': scrollPosition > 7 && scrollPosition < 28}">Ми – всеукраїнська благодійна організація,</span> <br class="mobile-br"/> <br class="br"/>
			<span :class="{'active': scrollPosition > 28 && scrollPosition < 42}">яка об’єднує українських та міжнародних волонтерів.</span><br/> 
			<span :class="{'active': scrollPosition > 42 && scrollPosition < 100}">Наша мета – підтримати Україну та не допустити гуманітарної кризи.</span> <br class="br"/> 
		</div>
	</div>
</template>

<script>
export default {

  name: 'TextBlock',

  data() {
    return{
    	scrollPosition: null,
    	isMobile: false
    }
  },
  computed: {
  	lang(){
      return this.$i18n.locale;
    }
  },
  methods: {
  	updateScroll() {
  		let windowscroll = window.pageYOffset;
  		let stickyBlocks = document.querySelector('.sticky-blocks').scrollHeight;
  		let hoverBlocksHeight = document.querySelector('.text-hover-block').scrollHeight;

  		if ((windowscroll + (this.isMobile ? 280 : 200)) > stickyBlocks){
  			this.scrollPosition = 100 * (windowscroll + (this.isMobile ? 280 : 200) - stickyBlocks) / hoverBlocksHeight;
  		}
    },
    mobileVers: function() {
      this.isMobile = window.innerWidth < 767;
    }
  },
  mounted() {
  	this.mobileVers();
    window.addEventListener('scroll', this.updateScroll);
  },
  created: function() {
    window.addEventListener('resize', this.mobileVers);
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.mobileVers);
  }
}

</script>

<style lang="scss">
	.text-hover-block{
		position: relative;
		padding: 220px 0px;
		background-color: #000;
		.br{
			display: none;
		}
		@media (max-width: 1023px){
			padding: 190px 0;
		}
		@media (max-width: 767px){
			padding: 84px 0;
			.br{
				display: inline;
			}
			.mobile-br{
				display: none;
			}
		}
		span {
			font-weight: 700;
			font-size: 80px;
			line-height: 109px;
			letter-spacing: -0.05em;
			color: rgba(255, 255, 255, 0.35);
			transition: color .4s ease-in-out;
			@media (max-width: 767px){
				font-size: 42px;
				line-height: 57px;
			}
			&.active{
				color: #fff;
			}
		}
	}
</style>