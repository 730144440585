<template>
	<div class="faq-wrapp">
		<div class="faq container">
			<div class="faq-wrapp__title">
				FAQ
			</div>		
			<div class="faq__block">
				<div v-for="(item,index) in faqs" class="faq__item" @click="open(index+1)">
					<div class="faq__title" :id="'faq-'+(index+1)">
					 	<span>{{item.title}}</span>
						<img class="faq__icon" src="@/assets/icons/arrow-down.svg" alt="arrow">
					</div>
					<div class="faq__answer" :id="(index+1)+'desc'">
					 	<span v-html="item.answer">
					 		
					  </span>
					</div>
				</div>
			</div>	
		</div>
	</div>

</template>

<script>
export default {

  name: 'FaqBlock',

  data() {
    return{
    	faqs: [
	      {	
	    		title: 'Вы оказываете помощь только в пределах Украины?',
	    		answer: 'Нет. По возможности предоставляем контакты людей, которые могут помочь с перевозками и размещением в соседних странах: Польша, Румыния, Молдова, Германия, Испания, США, Канада.'
	    	},{	
	    		title: 'В течение какого времени мне ждать выполнения моего запроса о помощи?',
	    		answer: 'Нет. По возможности предоставляем контакты людей, которые могут помочь с перевозками и размещением в соседних странах: Польша, Румыния, Молдова, Германия, Испания, США, Канада.'
	    	},{	
	    		title: 'Вы помогаете военным?',
	    		answer: 'Нет. По возможности предоставляем контакты людей, которые могут помочь с перевозками и размещением в соседних странах: Польша, Румыния, Молдова, Германия, Испания, США, Канада.'
	    	},{	
	    		title: 'Я не могу провести оплату. В чем проблема?',
	    		answer: 'Нет. По возможности предоставляем контакты людей, которые могут помочь с перевозками и размещением в соседних странах: Польша, Румыния, Молдова, Германия, Испания, США, Канада.'
	    	},{	
	    		title: 'Как я могу проверить подлинность Фонда?',
	    		answer: 'Нет. По возможности предоставляем контакты людей, которые могут помочь с перевозками и размещением в соседних странах: Польша, Румыния, Молдова, Германия, Испания, США, Канада.'
	    	}
	    ]
    }
  },
  computed: {
  	lang(){
  		return 'en'
  	}
  },
  methods: {
  	open(id) {
      let container = document.getElementById(id+'desc');
  		let item = container.parentElement;

  		if (!item.classList.contains("active")){
  				item.classList.add("active");
  		} else {
					item.classList.remove("active");
  		}
      let iconTitle = event.currentTarget.querySelector('.faq__icon');

      if (!container.classList.contains("active")) {
          container.classList.add("active");
          container.style.height = "auto";

          iconTitle.classList.add("active");
          let height = container.clientHeight + "px";

          container.style.height = "0px";

          setTimeout(function () {
            container.style.height = height;
          }, 0);
        } else {
          container.style.height = "0px";
          iconTitle.classList.remove("active");

          container.addEventListener(
            "transitionend",
            function () {
              container.classList.remove("active");
            },
              {
                once: true
              }
          );
        }
    },
  }
}

</script>

<style lang="scss">
	.faq{
		&-wrapp{
			background-color: #F3F3F3;
			padding: 104px 0 150px;
			@media (max-width: 1023px) {
				padding: 70px 0 70px;
			}
			@media (max-width: 767px) {
				padding: 40px 0;
			}
			&__title{
				font-weight: 700;
				font-size: 32px;
				line-height: 44px;
				text-align: center;
				color: #10142D;
				text-align: center;
				padding-bottom: 54px;
				@media (max-width: 767px) {
					padding-bottom: 30px;
				}
			}
		}
		
		&__block{
			background-color: #FDFDFD;
			border-radius: 20px;
			overflow: hidden;
			@media (max-width: 767px) {
				border-radius: 10px;
			}
		}
	}

.faq{
	&__item {
		width: 100%;
  	padding: 62px 94px 22px;
  	background-color: #FBFBFB;
  	transition: background-color .3s ease-in-out;
  	cursor: pointer;
	  &:last-child{
			padding-bottom: 60px;
			@media (max-width: 767px) {
				padding-bottom: 50px;
			}
		}
		&.active{
			background-color: #fff;
		}
		@media (max-width: 1023px) {
			padding: 62px 54px 22px;
		}
		@media (max-width: 767px) {
			padding: 40px 20px 22px 20px;
		}
	}
	&__title{
		width: 100%;
	  font-weight: 600;
		font-size: 20px;
		line-height: 27px;
		letter-spacing: -0.03em;
		color: #10142D;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		span{
			font-weight: 600;
			font-size: 20px;
			line-height: 27px;
			letter-spacing: -0.03em;
			color: #10142D;
			@media (max-width: 767px) {
				font-size: 16px;
				line-height: 22px;
			}
		}
	}
	&__icon{
		margin-left: 16px;
		transition: all .4s ease-in-out;
		width: 26px;
		margin-top: 8px;
		&.active{
			transform: rotate(180deg);
		}
		@media (max-width: 767px) {
			width: 16px;
			margin-left: 24px;
			margin-right: 20px;
		}
	}
	&__answer{
		width: 100%;
	  transition: height 0.35s ease-in-out;
	  overflow: hidden;
	  // padding-bottom: 37px;
	  span{
			padding-top: 24px;
		  height: 100%;
		  display: block;
		  font-weight: 400;
			font-size: 18px;
			line-height: 25px;
			letter-spacing: -0.03em;
			color: #6D6D6D;
			padding-bottom: 20px;
			@media (max-width: 767px) {
				padding-top: 15px;
				font-size: 14px;
				line-height: 19px;
				padding-bottom: 18px;
			}
		}
		&:not(.active) {
		  display: none;
		}
	}
}
</style>