<template>
	<div class="supported-block">
		<div class="title">
			{{$t('partners_title')}} 
		</div>
		<div class="projects-img">
			<div>
			<img src="@/assets/img/logos/1.png" alt="sa" style="width: 88px; min-width: 88px; margin-right: 44px;">
			</div>
			<div>
			<img src="@/assets/img/logos/2.png" alt="th" style="width: 96px; min-width: 96px; margin-right: 24px;">
			</div>
			<div>
			<img src="@/assets/img/logos/3.png" alt="kfs" style="width: 96px; min-width: 96px; margin-right: 44px;">
			</div>
			<div>
			<img src="@/assets/img/logos/4.png" alt="kss" style="width: 96px; min-width: 96px; margin-right: 44px;">
			</div>
			<div>
			<img src="@/assets/img/logos/5.png" alt="kss" style="width: 96px; min-width: 96px; margin-right: 44px;">
			</div>
			<div>
			<img src="@/assets/img/logos/6.png" alt="kss" style="width: 96px; min-width: 96px; margin-right: 44px;">
			</div>
			<div>
			<img src="@/assets/img/logos/7.png" alt="kss" style="width: 96px; min-width: 96px; margin-right: 44px;">
			</div>
			<div>
			<img src="@/assets/img/logos/8.png" alt="kss" style="width: 96px; min-width: 96px;">
			</div>
		</div>
	</div>
</template>

<script>
export default {

  name: 'SupportedBlock',

  data() {
    return{
    }
  },
  computed: {
  },
  methods: {
  }
}

</script>

<style lang="scss">
	.supported-block{
		height: 130px;
		padding: 24px 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		@media (max-width: 767px){
			flex-direction: column;
			height: auto;
			padding: 45px 0px 45px 20px;
		}
		.title{
			font-weight: 400;
			font-size: 16px;
			line-height: 22px;
			letter-spacing: -0.03em;
			color: #6D6D6D;
			padding-right: 46px;
			@media (max-width: 767px){
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
				text-align: center;
				letter-spacing: 0.2em;
				text-transform: uppercase;
				padding-right: 0;
				padding-right: 20px;
			}
		}
		.projects-img{
			display: flex;
			align-items: center;
			@media (max-width: 767px){
				// display: grid;
				// grid-template-columns: 1fr 1fr;
    		// grid-gap: 20px 25px;
    		padding-top: 25px;
			}
			div{
				@media (max-width: 767px){
					display: flex;
			    justify-content: center;
			    align-items: center;
				}
			}
			img {
				@media(max-width: 767px){
					margin-right: 16px !important;
    			width: 100% !important;	
				}
			}
		}
	}

	@media(max-width: 767px){
		.projects-img {
			width: 100%;
			overflow-x: auto; 
			div{
			// 	&:nth-child(1){
			// 			img{
			// 				width: 118px !important;
			// 				height: 62px !important;
			// 				min-height: 62px !important;
			// 			}
			// 		}
			// 		&:nth-child(2){
			// 			img {
			// 				width: 80px !important;
			// 				height: 62px !important;
			// 				min-height: 62px !important;
			// 			}
			// 		}
			// 		&:nth-child(3){
			// 			img {
			// 				width: 96px !important;
			// 				height: 110px !important;
			// 				min-height: 110px !important;
			// 			}
			// 		}
			// 		&:nth-child(4){
			// 			img {
			// 				width: 128px !important;
			// 				height: 62px !important;
			// 				min-height: 62px !important;
			// 			}
			// 		}
			}
		}
	}
</style>