<template>
	<div class="team-block_wrapp">
		<div class="team-block_scroll">
			<div class="container">
				<div class="team-block">
					<div class="team-block_text">
						<div class="team-block_title">
							{{$t('team_title')}}
						</div>
						<!-- <div class="team-block_subtitle">
							17 members
						</div> -->
					</div>
					<div class="arrow-right pc">
						<img src="@/assets/icons/arrow-right.svg" alt="arrow">
					</div>
					<div class="team-block_members">
						<div class="arrow-right mobile">
							<img src="@/assets/icons/arrow-right.svg" alt="arrow">
						</div>
						<!-- <div class="logo-circle">
							<img src="@/assets/img/smidyuk-logo.png" alt="logo"> 
						</div> -->

						<div class="team-block_item bottom">
							<div class="team-block_item_img" style="background-color: #B9D0EF;">
								<img src="/img/team/kulakov.jpeg" alt="kulakov">
							</div>
							<span class="team-block_item_name">
								{{lang == 'ua' ? 'Олександр Кулаков' : 'Alexander Kulakov' }}
							</span>
							<div class="partners-item_place">
								{{lang == 'ua' ? 'Співзасновник' : 'Co-founder' }}
							</div>
						</div>
						<div class="team-block_item top">
							<div class="team-block_item_img" style="background-color: #B9D0EF;">
								<img src="/img/team/irina.jpg" alt="irina">
							</div>
							<span class="team-block_item_name">
								{{lang == 'ua' ? 'Ірина Збишко' : 'Iryna Zbyshko' }}
							</span>
							<div class="partners-item_place">
								{{lang == 'ua' ? 'Співзасновник' : 'Co-founder' }}
							</div>
						</div>
						<div class="team-block_item top">
							<div class="team-block_item_img"  style="background-color: #E4E4E4;">
								<img src="/img/team/max.png" alt="max">
							</div>
							<span class="team-block_item_name">
								{{lang == 'ua' ? 'Максим Циханівський' : 'Maksym Tsyhanivskiy' }}
							</span>
							<div class="partners-item_place">
								{{lang == 'ua' ? 'Співзасновник' : 'Co-founder' }}
							</div>
						</div>
						<div class="team-block_item bottom">
							<div class="team-block_item_img" style="background-color: #B9D0EF;">
								<img src="/img/team/roma.png" alt="roma">
							</div>
							<span class="team-block_item_name">
								{{lang == 'ua' ? 'Роман Смідюк' : 'Roman Smidyuk' }}
							</span>
							<div class="partners-item_place">
								{{lang == 'ua' ? 'Співзасновник' : 'Co-founder' }}
							</div>
						</div>
						<div class="team-block_item bottom">
							<div class="team-block_item_img" style="background-color: #B9D0EF;">
								<img src="/img/team/dudka.jpg" alt="dudka">
							</div>
							<span class="team-block_item_name">
								{{lang == 'ua' ? 'Олександр Дудка' : 'Alexander Dudka' }}
							</span>
							<div class="partners-item_place">
								{{lang == 'ua' ? 'Координатор' : 'Сoordinator' }}
							</div>
						</div>
						<div class="team-block_item top">
							<div class="team-block_item_img"  style="background-color: #E4E4E4;">
								<img src="/img/team/stesura.jpg" alt="stesura">
							</div>
							<span class="team-block_item_name">
								{{lang == 'ua' ? 'Маріна Стецюра' : 'Mariana Stetsura' }}
							</span>
							<div class="partners-item_place">
								{{lang == 'ua' ? 'Координатор' : 'Сoordinator' }}
							</div>
						</div>
						<div class="team-block_item bottom">
							<div class="team-block_item_img" style="background-color: #B9D0EF;">
								<img src="/img/team/zvekova.jpg" alt="Izvekova">
							</div>
							<span class="team-block_item_name">
								{{lang == 'ua' ? 'Ірина Звекова' : 'Iryna Izvekova' }}
							</span>
							<div class="partners-item_place">
								{{lang == 'ua' ? 'Координатор' : 'Сoordinator' }}
							</div>
						</div>
						<div class="team-block_item top">
							<div class="team-block_item_img"  style="background-color: #E4E4E4;">
								<img src="/img/team/protasuk.jpeg" alt="protasuk">
							</div>
							<span class="team-block_item_name">
								{{lang == 'ua' ? 'Андрій Протасюк' : 'Андрій Протасюк' }}
							</span>
							<div class="partners-item_place">
								{{lang == 'ua' ? 'Координатор' : 'Сoordinator' }}
							</div>
						</div>
						<div class="team-block_item bottom">
							<div class="team-block_item_img" style="background-color: #B9D0EF;">
								<img src="/img/team/kuznetsov.jpg" alt="Ivan">
							</div>
							<span class="team-block_item_name">
								{{lang == 'ua' ? 'Іван Кузнєцов' : 'Ivan Kuznetsov' }}
							</span>
							<div class="partners-item_place">
								UX Designer
							</div>
						</div>
					</div>
				</div>	
			</div>
		</div>
	</div>
</template>

<script>
export default {

  name: 'TeamBlock',

  data() {
    return{
    }
  },
  computed: {
		lang(){
      return this.$i18n.locale;
    }
  },
  methods: {
  }
}

</script>

<style lang="scss">
	.team-block{
		display: flex;
		align-items: center;
		position: relative;
		@media (max-width: 1023px) {
			flex-direction: column;
			align-items: flex-start;
		}
		&_wrapp{
			padding: 170px 0px 215px;
			@media (max-width: 1023px) {
				padding: 100px 0 140px;
				.container{
					padding: 0;
				}
			}
			@media (max-width: 767px) {
				padding: 40px 0 25px;
			}
		}
		&_text{
			display: flex;
			flex-direction: column;
			margin-right: 80px;
			@media (max-width: 1023px) {
				margin-right: 0;
				margin-bottom: 40px;
				padding-left: 20px;
			}
			@media (max-width: 767px) {
				margin-bottom: 24px;
			}
		}
		&_title{
			font-weight: 700;
			font-size: 85px;
			line-height: 90px;
			letter-spacing: -0.05em;
			// white-space: nowrap;
			color: #10142D;
			// max-width: 120px;
			@media (max-width: 767px) {
				font-size: 42px;
				line-height: 57px;
			}
		}
		&_subtitle{
			font-weight: 400;
			font-size: 18px;
			line-height: 21px;
			letter-spacing: -0.03em;
			color: #6D6D6D;
			padding-top: 32px;
			@media (max-width: 767px) {
				font-size: 14px;
				padding-top: 4px;
				padding-left: 6px;
			}
		}
		&_scroll{
			overflow: auto;
			&::-webkit-scrollbar{
				display: none;
			}
			-ms-overflow-style: none;
			scrollbar-width: none;
		}
		&_members{
			display: flex;
			align-items: center;
			padding-bottom: 90px;
			padding-top: 105px;
			position: relative;
			.logo-circle{
				position: absolute; 
				top: 0;
				left: 92px;
				z-index: 10;
				img {
					width: 234px;
					height: 234px;
					@media (max-width: 767px) {
						width: 182px;
						height: 182px;
					}
				}
			}
			@media (max-width: 1023px) {
				width: 100%;
				overflow: auto;
				padding-left: 20px;
			}
		}
		&_item{
			display: flex;
			flex-direction: column;
			margin-right: 32px;
			@media (max-width: 767px) {
				margin-right: 25px;
			}
			&.bottom{
				position: relative;
				top: 85px;
				@media (max-width: 767px) {
					top: 48px;
				}
			}
			&.top{
				@media (max-width: 767px) {
					position: relative;
    			top: -18px;
				}
			}
			.partners-item_place{
				width: max-content;
				margin-top: 12px;
				margin-left: auto;
				margin-right: auto;
			}
			&_img{
				height: 328px;
				width: 233px;
				min-width: 233px;
				border-radius: 20px;
				overflow: hidden;
				@media (max-width: 767px) {
					width: 180px;
					height: 254px;
					min-width: 180px;	
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			&_name{
				font-weight: 400;
				font-size: 18px;
				line-height: 27px;
				text-align: center;
				letter-spacing: -0.03em;
				color: #6D6D6D;
				margin-top: 20px;
				@media (max-width: 767px) {
					margin-top: 18px;
					font-size: 16px;
					line-height: 24px;
				}
			}
		}
		.arrow-right{
			position: absolute;
			top: 15px;
			right: 0;
			&.pc{
				@media (max-width: 1023px) {
					display: none;
				}
			}
			&.mobile{
				display: none;
				@media (max-width: 1023px) {
					display: block;
					right: 20px;
					top: 28px;
				}
			}
		}
	}
</style>