import Vue from "vue";
import VueScrollTo from 'vue-scrollto'
import router from './router'
import App from "./App.vue"
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import {i18n} from './locales'

Vue.use(Vuelidate)
Vue.use(VueMeta)

Vue.config.productionTip = false;
Vue.use(VueScrollTo, {
  offset: -20,
  duration: 1000,
  easing: "ease-in-out",
})
new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
