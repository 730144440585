<template>
   <div id="app">
    <!-- <Nav/> -->
      <router-view class="content" id="header-scroll"/>
    <Footer/>  
  </div>
</template>

<script>
import Nav from "./components/Nav.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Nav, Footer
  },
  data(){
    return {
    }
  },
  computed:{
    lang(){
      return this.$i18n.locale;
    },
    title() {
      if (this.lang === 'ua'){
        return 'Фонд Національного Розвитку |  Допоможемо людям України разом!'
      } else {
        return 'National Development Foundation | Our goal – help to Ukraine'
      }   
    },
    desc() {
      if (this.lang === 'ua'){
        return 'Всеукраїнська благодійна організація, яка об‘єднує українських та міжнародних волонтерів. Підтримуємо лікарні, медичні частини, військових лікарів. Гуманітарна допомога для центрів біженців. Вже зібрали $234,300 на допомогу, а зможемо ще більше. Кожен донат рятує країну та життя українців!'
      } else{
        return 'All-Ukrainian charitable organization that unites Ukrainian and international volunteers. We support hospitals, medical units, and military doctors. Humanitarian aid for refugee centers. We have already collected $234,300 in aid, and we can do even more. Every donation saves the country and the lives of Ukrainians!'
      }   
    },
    keywords(){
      if (this.lang === 'ua'){
        return 'гуманітарна допомога в україні, гуманітарна допомога переселенцям, допомога впо, як допомогти Україні, фонд допомоги українцям, благодійний фонд, війна в Україні допомога, евакуація населення'
      } else{
        return 'charity foundation, war in ukraine 2022, help ukraine refugees, donate to help ukraine, charity to help ukraine, how to volunteer to help ukraine, donate to ukraine, ukraine refugees how to help, help ukrainian children'
      }
    }
  },
  metaInfo(){
    return {
      htmlAttrs: {
        lang: this.lang,
      },
      title: this.title,
      description: this.desc,
      keywords: this.keywords,
      meta: [
        {vmid: 'og:title', property: 'og:title', content: this.title},
        {vmid: 'og:description', property: 'og:description', content: this.desc},
        {vmid: 'keywords', name: 'keywords', content: this.keywords}
      ]
    }
  }
};
</script>


<style lang="scss">

@import "./assets/css/fonts.css";

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
  line-height: 24px;
  color: inherit;
}

html, body{
  height: 100%;
}

#app {
  color: #020202;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  padding: 0px;
  margin: 0px;
}

img{
  max-width: 100%;
  max-height: 100%;
}

a{
  display: inline-block;
  text-decoration: none;
  /*color: #222;*/
}

ul{
  list-style: none;
}

.container{
  max-width: 1254px;
  margin: 0 auto;
  padding: 0px 20px;
}

.content{
  flex: 1 0;
  // padding-top: 130px;
}

.btn{
  font-family: 'Manrope';
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: #6D6D6D;
  padding: 0px 28px;
  height: 50px;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  transition: color .3s ease-in-out;
  &.transparent{
    border: 0.5px solid #6D6D6D;
    background: transparent;
    &:hover{
      background: linear-gradient(96.34deg, #FFE782 0%, #FFCE00 100%);
      border: none;
      color: #10142D;
      font-weight: 700;
      letter-spacing: -0.04em;
    }
  }
  &.dark{
    border: none;
    font-weight: 700;
    color: #fff;
    background: #10142D;
    &:hover{
      background: linear-gradient(96.34deg, #FFE782 0%, #FFCE00 100%);
      color: #10142D;
      letter-spacing: -0.04em;
    }
  }
}

.input{
  height: 50px;
  padding: 0 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.03em;
  color: #10142D;
  background-color: #F3F3F3;
  border: none;
  outline: none;
  &::placeholder{
    font-weight: 400;
    color: #6D6D6D;
  }
  &-wrapp{
    margin-bottom: 32px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
}
.textarea{
  padding: 14px 20px;
  resize: none;
  height: 100px;
}

</style>
