<template>
	<div class="donate-wrapp" id="donate-block">
		<div class="container">
			<div class="donate">
					<img src="@/assets/img/donate-img.jpg" alt="man">
					<div class="donate_block">
						<div class="donate_block__title">
							{{$t('donate_title')}}
						</div>
						<div class="line"></div>
						<div class="donate_block__grid">
							<div>
								<div class="title">
									$ 234,300 
								</div>
								<div class="subtitle">
									{{$t('donate_pledged')}}
								</div>
							</div>
							<div>
								<div class="title">
									774
								</div>
								<div class="subtitle">
									{{$t('donate_sponsors')}}
								</div>
							</div>
						</div>
						<div class="line"></div>
						<div class="donate_block__text">
							{{$t('donate_text')}}
						</div>
						<a href="https://secure.wayforpay.com/donate/ndforgua" class="btn dark">
							{{$t('header_donate')}}
						</a>
					</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {

  name: 'DonateBlock',

  data() {
    return{
    }
  },
  computed: {
  },
  methods: {
  }
}

</script>

<style lang="scss">
	.donate{
		&-wrapp{
			padding: 80px 0;
			background-color: #F3F3F3;
			@media (max-width: 1023px){
				padding: 20px 0px;
			}
		}
		background-color: #fff;
		border-radius: 30px;
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		@media (max-width: 1023px){
			grid-template-columns: 1fr;
		} 
		@media (max-width: 767px){
			border-radius: 10px;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
			@media (max-width: 1023px){
				display: none;
			}
		}
		&_block{
			padding: 75px 130px 75px 77px;
			@media (max-width: 767px){
				padding: 40px 20px;
			}
			&__title{
				font-weight: 700;
				font-size: 60px;
				line-height: 82px;
				letter-spacing: -0.05em;
				color: #10142D;
				padding-bottom: 30px;
				@media (max-width: 767px){
					font-size: 32px;
					line-height: 44px;
					padding: 0px 10px 15px 10px;
				}
			}
			&__text{
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: -0.03em;
				color: #6D6D6D;
				padding-top: 40px;
				padding-bottom: 50px;
				@media (max-width: 767px){
					font-size: 14px;
					line-height: 21px;
					padding-top: 20px;
					padding-bottom: 30px;
				}
			}
			.line{
				height: 1px;
				background-color: #BDBDBD;
				width: 100%;
				opacity: 0.3;
			}
			&__grid{
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 32px;
				padding: 30px 0;
				@media (max-width: 1248px) and (min-width: 1023px) {
					grid-template-columns: 1fr;
				}
				@media (max-width: 767px){
					display: flex;
					padding: 20px 15px 0px;
					grid-gap: 15px;
					flex-wrap: wrap;
					div:first-child{
						margin-right: 40px;
					}
				}
				> div{
					display: flex;
					flex-direction: column;
					@media (max-width: 767px){
						padding-bottom: 20px;
					}
				}
				.title{
					font-weight: 600;
					font-size: 36px;
					line-height: 49px;
					letter-spacing: -0.03em;
					color: #10142D;
					@media (max-width: 767px){
						font-size: 26px;
						line-height: 36px;
					}
				}
				.subtitle{
					font-weight: 500;
					font-size: 14px;
					line-height: 19px;
					letter-spacing: -0.03em;
					color: #6D6D6D;
					padding-top: 2px;
					@media (max-width: 767px){
						padding-top: 4px;
					}
				}
			}
		}
	}
</style>