<template>
	<div class="home">
    <div class="sticky-blocks" :class="{'sticky': isSticky}" :style="isSticky ? x : null">
      <Header/>
      <SupportedBlock/>
    </div>
    <div class="blocks">
      <TextBlock/>
      <!-- <ImageSensetiveBlock/> -->
      <DestructionBlock/>
      <ImageSensetiveTextBlock/>
      <LineHelpBlock/>
      <HelpTextImgBlock/>
      <TypeHelpBlock/>
      <!-- <PartnersBlock/> -->
      <TeamBlock/>
      <ProjectsBlock/>
      <!-- <FaqBlock/> -->
      <FormBlock/>
      <!-- <div class="video-wrapp">
        <VideoBlock/>
      </div> -->
      <InstaBlock/>
      <DonateBlock/>
    </div>
	</div>
</template>

<script>

import InstaBlock from "@/components/InstaBlock.vue";
import DonateBlock from "@/components/DonateBlock.vue";
import VideoBlock from "@/components/VideoBlock.vue";
import FormBlock from "@/components/FormBlock.vue";
import FaqBlock from "@/components/FaqBlock.vue";
import PartnersBlock from "@/components/PartnersBlock.vue";
import ProjectsBlock from "@/components/ProjectsBlock.vue";
import TeamBlock from "@/components/TeamBlock.vue";
import SupportedBlock from "@/components/SupportedBlock.vue";
import ImageSensetiveBlock from "@/components/ImageSensetiveBlock.vue";
import ImageSensetiveTextBlock from "@/components/ImageSensetiveTextBlock.vue";
import DestructionBlock from "@/components/DestructionBlock.vue";
import LineHelpBlock from "@/components/LineHelpBlock.vue";
import HelpTextImgBlock from "@/components/HelpTextImgBlock.vue";
import TypeHelpBlock from "@/components/TypeHelpBlock.vue";
import TextBlock from "@/components/TextBlock.vue";
import Header from "@/components/Header.vue";

export default {

  name: 'Home',
  components: {
   DonateBlock, InstaBlock, VideoBlock, FormBlock, FaqBlock, PartnersBlock, TeamBlock, ProjectsBlock, SupportedBlock, ImageSensetiveBlock, ImageSensetiveTextBlock, DestructionBlock, LineHelpBlock, HelpTextImgBlock, TypeHelpBlock, TextBlock, Header
  },

  data () {
    return {
      isSticky: false,
      marginSticky: null
    }
  },
   computed: {
    x () {
      return `transform: translateY(-${ this.marginSticky }px)`
    }
  },
  methods: {
    updateScroll() {
      let windowscroll = window.pageYOffset;
      let stickyBlocks = document.querySelector('.sticky-blocks');
      let stickyBlocksHeight = stickyBlocks.scrollHeight;
      let clientHeight = document.body.clientHeight;
  
      if (windowscroll + clientHeight > stickyBlocksHeight){
        this.isSticky = true;      
      } else {
        this.isSticky = false;
      }
    },
    countStickyCount(){
      let stickyBlocks = document.querySelector('.sticky-blocks');
      let stickyBlocksHeight = stickyBlocks.scrollHeight;
      let clientHeight = document.body.clientHeight;

      this.marginSticky = clientHeight > stickyBlocksHeight ? 0 : stickyBlocksHeight - clientHeight;
    }
  },
  mounted() {
    this.countStickyCount();
  },
  created: function() {
    window.addEventListener('resize', this.countStickyCount);
    window.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.countStickyCount);
    // window.removeEventListener('scroll', this.updateScroll);
  }
}
</script>

<style lang="scss">
  .video-wrapp{
    margin-bottom: 80px;
    @media (max-width: 1023px) {
      margin-bottom: 16px;
    }
  }
  .sticky-blocks{
    &.sticky{
      position: -webkit-sticky;
      position: -moz-sticky;
      position: -o-sticky;
      position: -ms-sticky;
      position: sticky;
      overflow: hidden;
      top: 0;
      z-index: 1;
    }
  }
  .blocks{
    position: relative;
    background-color: #fff;
    z-index: 300;
  }
</style>