import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Lang from './views/Lang.vue'
import Documents from './views/Documents.vue'

import {i18n} from './locales'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },{
      path: '/documents',
      name: 'Documents',
      component: Documents
    },{
      path: '/:lang',
      component: Lang,
      children: [{
        path: '',
        name: 'Home',
        component: Home
      },{
        path: 'documents',
        name: 'Documents',
        component: Documents
      }]
    },{
      path: '*',
      redirect: '/',
    }
  ]
})

router.beforeEach(async (to, from, next) => {
    let language = localStorage.getItem('lang');

   if (!(to.params.lang) && language !== 'en'){
        let path = (to.path === '/') ? '' : to.fullPath;
        next({path: '/' + language + path});
      } else {

        if(to.params.lang === 'ua' || !to.params.lang){
          next();
        }else{
          let path =(language === 'en') ? '' : language;
          next({path: '/'+ path})
        }
      }  
})

export default router