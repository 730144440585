<template>
	<div>
		<div class="sensetive-block" v-if="isMobile">
			<div class="text-block">
				<div class="title">
						{{$t('sensetive_title')}}
				</div>
				<div class="text">
					{{$t('sensetive_text')}}
				</div>
			</div>
		</div>
		<div class="sensetive-block" :class="{'hidden': !isShow}">
			<img class="img" src="/img/sensetive-img2.jpg" alt="image">
			<img class="filter" src="/img/sensetive-filter2.png" alt="filter">
			<div v-if="!isMobile" class="container">
				<div class="text-block">
					<div class="title">
						{{$t('sensetive_title')}}
					</div>
					<div class="text">
						{{$t('sensetive_text')}}
					</div>
				</div>
			</div>
		
			<div class="hide-block">
				<img src="@/assets/icons/show.svg" alt="icon">
				{{$t('sensetive_block')}}
				<a @click="hide"> 
					{{$t('sensetive_hide')}}
				</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {

  name: 'ImageSensetiveBlock',

  data () {
    return {
      isShow: true,
      isMobile: false
    }
  },
  methods: {
  	mobileVers: function() {
      this.isMobile = window.innerWidth < 767;
    },
    hide(){
      this.isShow = false;
    }
  },
  mounted: function(){
    this.mobileVers();
  },
  created: function() {
    window.addEventListener('resize', this.mobileVers);
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.mobileVers);
  }
}

</script>

<style lang="scss">
	.sensetive-block{
		position: relative;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
		@media (max-width: 767px){
			background: none;
		}
		.img{
			width: 100%;
			height: auto;
		}
		.filter{
			position: absolute;
			left: 0;
			width: 100%;
			top: 0;
			height: 100%;
		}
		.hide-block{
			position: absolute;
			bottom: 70px;
			left: 50%;
			transform: translateX(-50%);
			background: rgba(255, 255, 255, 0.2);
			backdrop-filter: blur(20px);
			border-radius: 50px;
			padding: 0px 22px;
			height: 52px;
			font-weight: 500;
			font-size: 14px;
			line-height: 21px;
			display: flex;
			align-items: center;
			color: #FFFFFF;
			z-index: 20;
			white-space: nowrap;
			flex-shrink: 0;
			width: max-content;
			@media(max-width: 767px){
				bottom: 18px;
			}
			img{
				width: 20px;
				height: 20px;
				margin-right: 5px;
			}
			a{
				margin-left: 20px;
				text-decoration: underline;
				font-weight: 700;
				cursor: pointer;
			}
		}
		&.hidden{
			display: none;
		}
		.text-block{
			position: absolute;
    	top: 50%;
    	transform: translateY(-50%);
    	@media(max-width: 767px){
    		position: relative;
    		transform: none;
    		top: auto;
    		padding: 50px 20px 20px;
    	}
			.title{
				font-weight: 600;
				font-size: 85px;
				line-height: 116px;
				color: #FFFFFF;
				@media(max-width: 767px){
					font-weight: 700;
					font-size: 42px;
					line-height: 57px;
					color: #10142D;
				}
			}
			.text{
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				color: #FFFFFF;
				padding-top: 36px;
				max-width: 380px;
				@media(max-width: 767px){
					font-weight: 400;
					font-size: 16px;
					line-height: 24px;
					letter-spacing: -0.03em;
					color: #6D6D6D;
					padding-top: 12px;
					max-width: none;
				}
			}
		}
	}
</style>