<template>
  <a href="https://www.instagram.com/uadevelopment.fund/" target="_blank" class="insta-block">
    <div class="insta-block__block logo">
      <img src="@/assets/img/insta/logo.svg" alt="logo">
    </div>
    <div class="insta-block__block full">
      <img src="@/assets/img/insta/woman.jpg" alt="woman">
    </div>
    <div class="insta-block__block insta">
       <img src="@/assets/img/insta/insta-icon.png" alt="logo">
       <span>@uadevelopment.fund</span>
    </div>
    <div class="insta-block__block">
      <img src="@/assets/img/insta/flag.jpg" alt="flag">
    </div>
    <div class="insta-block__block">
      <img src="@/assets/img/insta/food.jpg" alt="food">
    </div>
    <div class="insta-block__block hidden-pc">
      <img src="@/assets/img/insta/soldier.jpg" alt="food">
    </div>
  </a>
</template>

<script>
export default {

  name: 'InstaBlock',

  data() {
    return{
    }
  },
  computed: {
  },
  methods: {
  }
}

</script>

<style lang="scss">
	.insta-block{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    @media (max-width: 1023px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &__block{
      width: 100%;
      background-color: #10142D;
      height: 20vw;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 1023px) {
        height: 33.33vw;
      }
      &.hidden-pc{
        display: none;
        @media (max-width: 1023px) {
          display: block;
        }
      }
      &.full{
        img{
          width: 100%;
          height: 100%;
        }
      }
      &.insta{
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
            width: 40px; 
            height: 40px;
            @media (max-width: 1023px) {
              width: 16px;
              height: 16px;
            }
         }
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          color: #FFFFFF;
          padding-top: 24px;
          @media (max-width: 1023px) {
            font-weight: 700;
            font-size: 10px;
            line-height: 14px;
            padding-top: 8px;
          }
        }
      }
      &.logo{
        img{
          width: 110px;
          @media (max-width: 1023px) {
            width: 60px;
          }
        }
      }
    }
  }
</style>