<template>
	<div class="form-wrapp" id="contact-block">
		<div class="form_left" :class="{'active': !isWant}">
			<div class="form_left-wrapp">
				<div class="form_nav" :class="{'ua': lang == 'ua'}">
					<div class="form_nav__item" :class="{'active': isWant}" @click="isWant = true;">
						{{$t('form_want_help')}}
					</div>
					<div class="form_nav__item" :class="{'active': !isWant}" @click="isWant = false;">
						{{$t('form_need_help')}}
					</div>
					<div class="line" :class="{'active': !isWant}"></div>
				</div>
				<div class="form_texts">
					<div v-if="isWant" class="one" v-html="$t('form_want_help_text')"></div>
					<div v-if="!isWant" class="one" v-html="$t('form_need_help_text')"></div>
				</div>
			</div>
		</div>
		<div class="form_right">
			<div class="form_title">
				{{$t('form_title')}}
			</div>
			<transition name="fade" mode="out-in">
				<form v-if="!isSend" class="form">
					<div class="input-wrapp">
						<input type="text" class="input" v-model="name" required :placeholder="$t('form_name')">
						<span v-if="!$v.name.required && $v.name.$dirty" class="input__error">{{$t('form_error_name')}}</span>
					</div>
					<div class="input-wrapp">
						<input type="text" class="input" v-model="phone" required :placeholder="$t('form_phone')">
						<span v-if="!$v.phone.required && $v.phone.$dirty" class="input__error">{{$t('form_error_phone')}}</span>
					</div>
					<div class="input-wrapp">
						<input type="text" class="input" v-model="city" :placeholder="$t('form_city')">
					</div>
					<div class="input-wrapp">
						<textarea class="input textarea" v-model="text" rows="4" :placeholder="$t('form_text')"></textarea>
					</div>
					<button class="btn dark" type="submit" @click.prevent="send">
						{{$t('form_send')}}
					</button>
				</form>
				<div class="thanks" v-else style="min-height: 434px;">
					<div class="thanks_title">
						{{$t('form_thanks_title')}}
					</div>
					<div class="thanks_subtitle">
						{{$t('form_thanks_text')}}
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>

import axios from 'axios';
import { validationMixin } from 'vuelidate'
import { required, minLength, between, helpers, sameAs } from 'vuelidate/lib/validators'


export default {

  name: 'FormBlock',

  data() {
    return{
    	isWant: true,
    	isSend: false,
      isLoad: false,
      name: '',
      phone: '',
      city: '',
      text: ''
    }
  },
  mixins: [validationMixin],
  validations: {
    name: {
      required,
    },
    phone: {
      required,
    }
  },
  computed: {
  	inputErrorName: function() {
  		return !this.$v.name.required && this.$v.name.$dirty;
  	},
  	inputErrorPhone: function() {
  		return !this.$v.phone.required && this.$v.phone.$dirty || !this.$v.phone.phone && this.$v.phone.$dirty;
  	},
  	lang(){
      return this.$i18n.locale;
    }
  },
  methods: {
  	send(){
  		this.$v.$touch();
      if(!this.$v.$invalid){
	      let obj = {
	        name: this.name,
	        phone: this.phone,
	        city: this.city,
	        text: this.text,
	        status: this.isWant ? 'Хочу помочь' : 'Нужна помощь'
	      } 

	        this.isLoad = true;

	        axios.post('/send.php', obj)
	        .then(res => {
	         this.isSend = true; 
	        }).catch(err => {
	          console.log(err);
	        }).finally(()=>{
	          this.isLoad = false;
	        })
      	}
    }
  }
}

</script>

<style lang="scss">
	.form-wrapp{
		min-height: 960px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		width: 100%;
		@media (max-width: 1023px) {
			display: flex;
			flex-direction: column;
		}
	}
	.form{
		&_left{
			background: #006CFF;
			padding: 167px 90px 160px;
			transition: background-color .3s ease-in-out;
			&-wrapp{
				max-width: 482px;
				margin-left: auto;
				@media (max-width: 1023px) {
					margin: 0 auto;
				}
			}
			@media (max-width: 1023px) {
				padding: 110px 70px 115px;
			}
			@media (max-width: 767px) {
				padding: 60px 30px 86px;
			}
			&.active{
				background-color: #10142D;
			}
		}
		&_nav{
			display: flex;
			padding-left: 20px;
			position: relative;
			@media (max-width: 1023px) {
				padding-left: 0;
				// justify-content: center;
			}
			@media (max-width: 767px) {
				width: max-content;
				margin: 0 auto;
			}
			&__item{
				font-weight: 400;
				font-size: 20px;
				line-height: 27px;
				letter-spacing: -0.03em;
				color: #B9D0EF;
				padding: 10px 30px 12px;
				transition: font-weight .1s ease-in-out;
				min-width: 180px;	
				cursor: pointer;
				&.active{
					font-weight: 700;
					color: #FFFFFF;
				}
				&:first-child{
					margin-right: 12px;
					@media (max-width: 767px) {
						margin-right: 24px;
					}
				}
				@media (max-width: 767px) {
					padding: 10px 0px 14px;
					min-width: 120px;
					text-align: center;
				}
			}
			&.ua{
				.form_nav__item{
					text-align: center;
				}
				.line{
					width: 206px;
					&.active{
			  		transform: translateX(232px);
			  	}
				}
				@media (max-width: 767px) {
					flex-direction: column;
					.line{
						bottom: 68px;
						&.active{
					  	transform: translateY(72px);
					  	width: 232px;
					  }
					}
					.form_nav__item:first-child{
						margin-right: 0px;
						margin-bottom: 16px;
					}
				}
			}
			.line{
				position: absolute;
			  bottom: 0;
			  height: 4px;
			  width: 180px;
			  background-color: #FFFFFF;
			  border-radius: 4px;
			  transition: transform .3s ease-in-out;
			  &.active{
			  	transform: translateX(180px);
			  }
			  @media (max-width: 767px) {
			  	width: 120px;
			  	left: 0;
			  	&.active{
				  	transform: translateX(142px);
				  }
			  }
			}
		}
		&_texts{
			margin-top: 130px;
			@media (max-width: 767px) {
				margin-top: 60px;
			}
			.one{
				font-weight: 700;
				font-size: 85px;
				line-height: 116px;
				letter-spacing: -0.05em;
				color: #FFFFFF;
				@media (max-width: 767px) {
					font-size: 65px;
					line-height: 89px;
				}
				span {
					text-decoration: underline;
				}
			}
		}
		&_right{
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 40px 0 40px 190px;
			background: #fff;
			@media (max-width: 1348px) {
				 padding: 40px 0 40px 92px;
			}
			@media (max-width: 1200px) {
				 padding: 40px 0 40px 48px;
			}
			@media (max-width: 1023px) {
				padding: 60px 0;
			}
			@media (max-width: 767px){
				padding: 60px 25px;
			}
		}
	}
	.form{
		max-width: 354px;
		display: flex;
		flex-direction: column;
		@media (max-width: 767px){
			max-width: none;
		}
		@media (max-width: 1023px) {
			margin: 0 auto;
			width: 100%;
		}
		&_title{
			font-weight: 700;
			font-size: 60px;
			line-height: 82px;
			letter-spacing: -0.05em;
			color: #10142D;
			padding-bottom: 35px;
			@media (max-width: 1023px) and (min-width: 767px) {
				text-align: center;
			}
			@media (max-width: 767px){
				font-size: 42px;
				line-height: 57px;
				padding-bottom: 30px;
			}
		}
		input, textarea {
			width: 100%;
		}
		button {
			min-width: 150px;
			width: max-content;
			justify-content: center;
		}
	}
	.thanks_title{
		padding-bottom: 24px;
    font-weight: 600;
    font-size: 36px;
    line-height: 49px;
    letter-spacing: -0.03em;
    color: #10142D;
    @media (max-width: 767px){
    	font-size: 32px;
    	text-align: center;
    }
	}
	.thanks_subtitle{
		font-size: 18px;
    color: #6D6D6D;
    @media (max-width: 767px){
    	text-align: center;
    }
	}
	.input__error{
		font-size: 12px;
		margin-top: 8px;
		color: red;
	}
	
		.thanks{
			display: flex;
    	flex-direction: column;
    	padding-top: 40px;
    	@media (max-width: 767px){
    		justify-content: center;
    		align-items: center;
    		padding-top: 0;
    		padding-bottom: 80px;
			}
	}
</style>