<template>
	<div class="sensetive-block" :class="{'hidden': !isShow}">
		<img class="img" src="/img/sensetive-img1.jpg" alt="image">
		<img class="filter" src="/img/sensetive-filter1.png" alt="filter">
		<div class="hide-block">
			<img src="@/assets/icons/show.svg" alt="icon">
			Sensetive content
			<a @click="hide"> 
				Hide
			</a>
		</div>
	</div>
</template>

<script>
export default {

  name: 'ImageSensetiveBlock',

  data () {
    return {
      isShow: true
    }
  },
  methods: {
  	mobileVers: function() {
      this.isMobile = window.innerWidth < 767;
    },
    hide(){
      this.isShow = false;
    }
  },
  mounted: function(){
    this.mobileVers();
  },
  created: function() {
    window.addEventListener('resize', this.mobileVers);
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.mobileVers);
  }
}

</script>

<style lang="scss">
	.sensetive-block{
		position: relative;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
		.img{
			width: 100%;
			height: auto;
		}
		.filter{
			position: absolute;
			left: 0;
			width: 100%;
			top: 0;
			height: 100%;
		}
		.hide-block{
			position: absolute;
			bottom: 70px;
			left: 50%;
			transform: translateX(-50%);
			background: rgba(255, 255, 255, 0.2);
			backdrop-filter: blur(20px);
			border-radius: 50px;
			padding: 0px 22px;
			height: 52px;
			font-weight: 500;
			font-size: 14px;
			line-height: 21px;
			display: flex;
			align-items: center;
			color: #FFFFFF;
			z-index: 20;
			img{
				width: 20px;
				height: 20px;
				margin-right: 5px;
			}
			a{
				margin-left: 20px;
				text-decoration: underline;
				font-weight: 700;
				cursor: pointer;
			}
		}
		&.hidden{
			display: none;
		}
	}
</style>