import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './lang/en.json'
import ua from './lang/ua.json'


Vue.use(VueI18n)

const messages = {
  'en': en,
  'ua': ua
}

let lang = localStorage.getItem('lang');


let link = window.location.pathname;

let sp = link.split('/');
let spl = sp.length;
let i = 0;

  while (i<spl) {
    if (sp[i].length == 2){
      lang = sp[i];
    }
    i++
  }

if(!lang){
  lang = navigator.language || navigator.userLanguage;
  lang = lang.split('-')[0].toLowerCase();
}

if(!(lang in messages)){
  lang = 'en';
}

localStorage.setItem('lang', lang);


const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'en',
  messages: messages
});

export {i18n}



