<template>
	<div class="type-help-wrapp">
		<div class="type-help">
			<div class="container relative">
				<div class="type-help__title">
					{{$t('type-help_title')}}
				</div>
				<div class="arrow-right">
					<img src="@/assets/icons/arrow-right.svg" alt="arrow">
				</div>
			</div>
			<div class="type-help-scroll">
				<div class="type-help-overflow container">
					<div class="type-help__block">
						<div class="type-item">
							<div class="type-item__icon">
								💊
							</div>
							<div class="type-item__title">
								{{$t('type-help_block1_title')}}
							</div>
							<div class="type-item__text">
								{{$t('type-help_block1_text')}}
							</div>
						</div>
						<div class="type-item">
							<div class="type-item__icon">
								📚
							</div>
							<div class="type-item__title">
								{{$t('type-help_block2_title')}}
							</div>
							<div class="type-item__text">
								{{$t('type-help_block2_text')}}
							</div>
						</div>
						<div class="type-item">
							<div class="type-item__icon">
								🚌
							</div>
							<div class="type-item__title">
								{{$t('type-help_block3_title')}}
							</div>
							<div class="type-item__text">
								{{$t('type-help_block3_text')}}
							</div>
						</div>
						<div class="type-item wide">
							<div class="type-item__icon">
								👦
							</div>
							<div class="type-item__title">
								{{$t('type-help_block4_title')}}
							</div>
							<div class="type-item__text">
								{{$t('type-help_block4_text')}}
							</div>
						</div>
						<div class="type-item">
							<div class="type-item__icon">
								🚨
							</div>
							<div class="type-item__title">
								{{$t('type-help_block5_title')}}
							</div>
							<div class="type-item__text">
								{{$t('type-help_block5_text')}}
							</div>
						</div>
						<div class="type-item">
							<div class="type-item__icon">
								🐕
							</div>
							<div class="type-item__title">
								{{$t('type-help_block6_title')}}
							</div>
							<div class="type-item__text">
								{{$t('type-help_block6_text')}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="container">
				<div class="type-help__second">
					<div class="text" v-html="$t('type-contacts_title')"></div>
					<div class="items">
						<div class="item">
							<img src="@/assets/icons/phone.svg" alt="phone">
							{{$t('type-contacts_text1')}} 
						</div>
						<div class="line"></div>
						<div class="item">
							<img src="@/assets/icons/worker.svg" alt="phone">
							{{$t('type-contacts_text2')}}
						</div>
						<div class="line"></div>
						<div class="item">
							<img src="@/assets/icons/biceps.svg" alt="phone">
							{{$t('type-contacts_text3')}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {

  name: 'TypeHelpBlock',

  data() {
    return{
    }
  },
  computed: {
  },
  methods: {
  }
}

</script>

<style lang="scss">
	.type-help{
		&-wrapp{
			padding: 194px 0px 150px;
			background: #F3F3F3;
			@media (max-width: 1023px) {
				padding: 110px 0px 90px;
			}
			@media (max-width: 767px) {
				padding: 60px 0px 50px;
				background-color: transparent;
			}
		}
		&__title{
			font-weight: 700;
			font-size: 60px;
			line-height: 82px;
			letter-spacing: -0.05em;
			color: #10142D;
			padding-bottom: 80px !important;
			max-width: 807px;
			@media (max-width: 767px) {
				font-size: 42px;
				line-height: 57px;
				padding-bottom: 0px !important;
			}
		}
		&__block{
			display: flex;
			align-items: center;
			border-radius: 20px;
			@media (max-width: 767px){
				flex-direction: column;
				padding: 60px 0 75px;
			}
			.type-item{
				width: 304px;
				min-width: 304px;
				min-height: 345px;
				padding: 65px 48px 20px;
				background: #FFFFFF;
				box-shadow: 0px 15px 150px rgba(0, 0, 0, 0.08);
				@media (max-width: 767px){
					box-shadow: 0px 15px 100px rgba(0, 0, 0, 0.12);
				}
				&:first-child{
					border-radius: 20px 0px 0px 20px;
					@media (max-width: 767px) {
						border-radius: 20px 20px 0px 0px;
					}
				}
				&:last-child{
					border-radius: 0px 20px 20px 0px;
					@media (max-width: 767px) {
						border-radius: 0px 0px 20px 20px;
						box-shadow: 15px 0px 100px rgba(0, 0, 0, 0.12);
					}
				}
				&__title{
					font-weight: 700;
					font-size: 26px;
					line-height: 36px;
					letter-spacing: -0.05em;
					color: #000000;
					padding-top: 32px;
				}
				&__text{
					font-weight: 400;
					font-size: 18px;
					line-height: 27px;
					color: #6D6D6D;
					padding-top: 20px;
				}
				&__icon{
					font-weight: 700;
					font-size: 40px;
					line-height: 55px;
					letter-spacing: -0.05em;
					color: #000000;
					text-align: center;
				}
				@media(min-width: 767px){
					&.wide{
						padding-right: 28px;
						.type-item__icon{
							padding-right: 20px;
						}
						.type-item__text{
							padding-right: 14px;
						}
					}
				}
			
			}
		}
		&-scroll{
			overflow: auto;
			&::-webkit-scrollbar{
				display: none;
			}
		}
		&__second{
			margin-top: 75px;
			background: #FFFFFF;
			box-shadow: 0px 12px 200px rgba(0, 0, 0, 0.08);
			border-radius: 20px;
			padding: 136px 112px;
			grid-gap: 32px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			@media (max-width: 1023px) {
				grid-template-columns: 1fr;
				padding: 76px 72px;
			}
			@media (max-width: 767px) {
				padding: 0;
				background-color: transparent;
				box-shadow: none;
				margin-top: 0;
			}
			.text {
				font-weight: 700;
				font-size: 60px;
				line-height: 82px;
				letter-spacing: -0.05em;
				color: #10142D;
				@media (max-width: 767px) {
					font-size: 42px;
					line-height: 57px;
				}
			}
			span {
				text-decoration: underline;
			}
			.items {
				.item {
					display: flex;
					align-items: center;
					padding: 34px 0px;
					font-weight: 400;
					font-size: 18px;
					line-height: 25px;
					letter-spacing: -0.05em;
					color: #6D6D6D;
					@media (max-width: 767px) {
						padding: 25px 0px;
					}
					img {
						width: 47px;
						height: 47px;
						margin-right: 25px;
					}
					&:first-child{
						@media (max-width: 767px) {
							padding-top: 10px;
						}
					}
					&:last-child{
						@media (max-width: 767px) {
							padding-bottom: 0px;
						}
					}
				}
				.line{
					background-color: #C4C4C4;
					opacity: 0.3;
					height: 1px;
					@media (max-width: 767px) {
						margin-left: 10px;
						margin-right: 10px;
					}
				}
			}
		}
		.relative {
			position: relative;
		}
		.arrow-right{
			position: absolute;
			right: 0;
			bottom: 100px;
			@media (max-width: 1300px) {
				right: 20px;
				bottom: 40px;
			}
			@media (max-width: 767px) {
				display: none;
			}
		}
	}
</style>