<template>
	<div id="partners-block" class="partners-block container">
		<div class="partners-block__title">
			{{$t('partners_title')}}
		</div>
		<div class="partners-block__block">
			<div v-for="item in partners" class="partners-item">
				<div class="partners-item_img">
					<img :src="item.img" :alt="item.name">
				</div>
				<div class="partners-item_name">
					{{item.name}}
				</div>
				<div class="partners-item_place" v-html="item.place">
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {

  name: 'PartnersBlock',

  data() {
    return{
    	partners: [
    	{
    		name: 'Denis Savchenko',
    		place: 'Crimea SOS',
    		img: '/img/partners/1.png'
    	},{
    		name: 'Helena Martinez Soler',
    		place: 'FC Barcelona',
    		img: '/img/partners/2.png'
    	},{
    		name: 'Taras Gerega',
    		place: 'Epicentr',
    		img: '/img/partners/3.png'
    	},{
    		name: 'Sophia Ann Kristin',
    		place: 'Employee of the </br>German parliament',
    		img: '/img/partners/4.png'
    	}]
    }
  },
  computed: {
  },
  methods: {
  }
}

</script>

<style lang="scss">
	.partners-block{
		padding-top: 88px !important;
		padding-bottom: 60px !important;
		@media (max-width: 767px) {
			padding-top: 40px !important;
			padding-bottom: 46px !important;
		}
		&__title{
			font-weight: 700;
			font-size: 60px;
			line-height: 82px;
			letter-spacing: -0.05em;
			color: #10142D;
			padding-bottom: 56px;
			@media (max-width: 767px) {
				font-size: 42px;
				line-height: 57px;
				padding-bottom: 38px;
			}
		}
		&__block{
			display: grid;
			grid-gap: 60px;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			@media (max-width: 1023px) {
				grid-gap: 24px;
			}
			@media (max-width: 767px) {
				display: flex;
				padding-bottom: 20px;
				overflow: auto;
				&::-webkit-scrollbar{
					display: none;
				}
			}
		}
	}
	.partners-item{
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (max-width: 767px) {
			width: 127px;
			min-width: 127px;
			margin-right: 40px;
		}
		&_img{
			border-radius: 20px;
			@media (max-width: 767px) {
				border-radius: 10px;
				width: 100%;
				height: 190px;
				min-width: 127px;
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
		&_name{
			font-weight: 700;
			font-size: 20px;
			line-height: 30px;
			letter-spacing: -0.03em;
			color: #10142D;
			padding: 20px 0px 12px;
			text-align: center;
			@media (max-width: 767px) {
				font-size: 18px;
				line-height: 27px;
				padding-top: 0;
				padding-bottom: 0;
				margin: 8px 0 12px;
				// height: 56px;
			}
		}
		&_place{
			font-weight: 500;
			font-size: 11px;
			line-height: 16px;
			display: flex;
			align-items: center;
			letter-spacing: -0.03em;
			color: #6D6D6D;
			background: #E8E8E8;
			border-radius: 50px;
			height: 32px;
			padding: 0 18px;
			text-align: center;
			br{
				display: none;
				@media (max-width: 767px) {
					display: inline;
				}
			}
			@media (max-width: 767px){
				white-space: nowrap;
			}
		}
	}
	
</style>