<template>
	<div class="projects-block-wrapp" id="partners-block">
		<div class="partners-block__title container" style="margin-bottom: 64px;">
			{{$t('partners_title')}}
		</div>
		<div class="projects-block container">
			<div class="projects-block__item">
				<img src="@/assets/img/logos/1.png" alt="sa">
			</div>
			<div class="projects-block__item">
				<img src="@/assets/img/logos/2.png" alt="th">
			</div>
			<div class="projects-block__item">
				<img src="@/assets/img/logos/3.png" alt="kfs">
			</div>
			<div class="projects-block__item">
				<img src="@/assets/img/logos/4.png" alt="kss">
			</div>
			<div class="projects-block__item">
				<img src="@/assets/img/logos/5.png" alt="kss">
			</div>
			<div class="projects-block__item">
				<img src="@/assets/img/logos/6.png" alt="kss">
			</div>
			<div class="projects-block__item">
				<img src="@/assets/img/logos/7.png" alt="kss" >
			</div>
			<div class="projects-block__item">
				<img src="@/assets/img/logos/8.png" alt="kss">
			</div>
		</div>
	</div>
</template>

<script>
export default {

  name: 'ProjectsBlock',

  data() {
    return{
    }
  },
  computed: {
  },
  methods: {
  }
}

</script>

<style lang="scss">
	.projects-block{
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-gap: 24px;
		min-height: 98px;
		@media (max-width: 767px) {
			grid-template-columns: 1fr 1fr;
			grid-gap: 20px 25px;
			min-height: auto;
		}
		div {
			display: flex;
			justify-content: center;
			align-items: center;
			// border-right: 1px solid #C4C4C4;
			&:last-child{
				border-right: none;
			}
			@media (max-width: 767px) {
				border-right: none;
			}
		}		
		&-wrapp{
			background-color: #F5F5F5;
			padding: 64px 0px;
			@media (max-width: 767px) {
				padding: 54px 28px;
			}
		}
	}	

@media (max-width: 767px){
	.projects-block__item{
		// &:nth-child(1){
		// 	img{
		// 		width: 118px !important;
		// 		height: 62px;
		// 		min-height: 62px;
		// 	}
		// }
		// &:nth-child(2){
		// 	img{
		// 		width: 73px;
		// 		height: 57px;
		// 		min-height: 57px;
		// 	}
		// }
		// &:nth-child(3){
			img{
				width: auto;
				height: 96px;
				min-height: 96px;
		}
		&:nth-child(8){
			img{
				height: 70px;
				min-height: 70px;
			}
		}
		&:nth-child(6){
			img{
				height: 70px;
				min-height: 70px;
			}
		}
		&:nth-child(2){
			img{
				height: 90px;
				min-height: 90px;
			}
		}
	}
}
</style>